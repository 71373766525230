// export const URL = process.env.REACT_APP_BASE_URL || 'https://k8s.dev.locate.just.engineer/api'
export const URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000/api';
export const URL_RATING_SERVICES =
  process.env.REACT_APP_RATING_SERVICES_BASE_URL || 'https://dev-rating.locate.sa/api';
export const URL_ANALYSIS =
  process.env.REACT_APP_URL_ANALYSIS || 'https://dev-analysis.locate.just.engineer';

// export const URL_SOCKET = process.env.REACT_APP_SOCKET_URL || 'https://k8s.dev.locate.just.engineer';
export const URL_SOCKET = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';
export const URL_STORAGE_FILE_ENDPOINT =
  process.env.REACT_APP_BASE_URL_FILE_STORAGE || 'https://dev.batch.locate.sa/api';
export const URL_PAYMENT_SERVICE =
  process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL || 'https://dev.payment.locate.sa';
export const URL_SUPPLIER_SERVICES =
  process.env.REACT_APP_SUPPLIER_SERVICES_URL || 'https://dev.api.supplier.locate.sa';

export const URL_MARKETING_CAMPAIGN =
  process.env.REACT_APP_MARKETING_SERVICES_URL || 'https://dev.mkt.locate.just.engineer';

export const URL_SHOW_POPUP = `${URL}/admin/api/v1`;

export const setToken = async (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

export const getToken = () => {
  return localStorage.getItem('accessToken');
};

export const config = {
  SOCKET_URL: `${URL_SOCKET}/socket`,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
  headersUpload: {
    'content-type': 'multipart/form-data',
    Authorization: `Bearer ${getToken()}`,
  },
  DASHBOARD: {
    ORDER_ANALYSIS: `${URL_ANALYSIS}/admin/api/v1/operation/order/order-analysis`,
    ORDER_ANALYSIS_EARN: `${URL_ANALYSIS}/admin/api/v1/operation/order/order-analysis/total-earn`,
    DRIVER_ANALYSIS: `${URL_ANALYSIS}/admin/api/v1/operation/driver/driver-analysis`,
  },
  API_URL: {
    RATING: {
      RATING_OPERATION_DRIVER: `${URL_RATING_SERVICES}/v1/operation/drivers`,
      RATING_OPERATION_RATING_START: `${URL_RATING_SERVICES}/v1/operation/rating-star`,
      RATING_OPERATION_RATING_START_STORE: `${URL_RATING_SERVICES}/v1/operation/rating-star/stores`,
      LOGS: '/logs-rating-star',
      RATING_OPERATION_DRIVER_EXCEL: `${URL_RATING_SERVICES}/v1/operation/drivers/excel`,
      RATING_OPERATION_RATING_START_EXCEL: `${URL_RATING_SERVICES}/v1/operation/rating-star/excel`,
    },
    SYNC_FILE: {
      STORAGE_EXCEL: `${URL_STORAGE_FILE_ENDPOINT}/v1/storage-excel`,
      RECOVERY_FILE: `${URL}/admin/api/v1/citc-timeout-logs/recovery`,
      RECOVERY_FILE_STATUS: `${URL_STORAGE_FILE_ENDPOINT}/v1/storage-excel/update-recovery-status/`,
      RECOVERY_REPORT_FILE: (uuid) =>
        `${URL_STORAGE_FILE_ENDPOINT}/v1/excel/download/citc-response/${uuid}`,
    },
    AUTH: {
      LOGIN: `${URL}/admin/api/v1/login`,
      LOGOUT: `${URL}/admin/api/v1/logout`,
      PROFILE: `${URL}/admin/api/v1/profile`,
      LIST_OPERATORS: `${URL}/admin/api/v1/users`,
      REGISTER_OPERATORS: `${URL}/admin/api/v1/register`,
      PASSWORD: `${URL}/admin/api/v1/users`,
    },
    ORDERS: {
      GET_ALL_ORDERS: `${URL}/admin/api/v1/orders/`,
      GET_ORDER: `${URL}/admin/api/v1/orders/`,
      GET_ORDERS_PAGE: `${URL}/admin/api/v1/orders/`,
      GET_ORDERS_FAKE: `${URL}/admin/api/v1/orders/unnormal/`,

      EXPORT_EXCEL: `${URL}/admin/api/v1/orders/excel-by-service/`,
      EXPORT_STC_EXCEL: `${URL}/admin/api/v1/orders/stc-excel/`,
      EXECUTE_ORDER: `${URL}/admin/api/v2/orders/execute/`,
      CANCEL_ORDER: `${URL}/admin/api/v2/orders/cancel/`,
      EXPORT_EXCEL_MISSING_ITEM: `${URL}/admin/api/v1/orders/order-missing-items/stc-excel/`,

      ASSIGN_ORDER: `${URL}/admin/api/v2/orders/assign/`,
      DRIVER_ASSIGN_ORDER: `${URL}/v1/orders/assign/`,
      COMPANY: `${URL}/admin/api/v1/orders/company/`,
      CUSTOMER_CANCEL_ORDER: `${URL}/admin/api/v2/orders/un-assign/`,
      CREATE_FAKE_ORDER: `${URL}/admin/api/v1/orders/create-fake/`,
      DETAIL_ORDER: (id) => `${URL_SHOW_POPUP}/orders/${id}/detail`,
      RE_ORDER: (id) => `${URL_SHOW_POPUP}/orders/${id}/re-order`,
      GET_CURRENT_DELIVERY_STATUS: `${URL}/admin/api/v1/settings/delivery-status/current-status`,
      CHANGE_CURRENT_DELIVERY_STATUS: `${URL}/admin/api/v1/settings/delivery-status/update`,

      GET_ORDER_VIEW: `${URL}/admin/api/v2/orders/view`,
      GET_HISTORY: (id) => `${URL}/admin/api/v3/orders/${id}/history`,
      TRANSFER_PREVIEW: (id) => `${URL}/admin/api/v3/orders/${id}/transfered-preview-order`,
    },
    DRIVERS: {
      DRIVER_ORDER_DISCOUNT: `${URL}/v2/drivers/list-discount-order/`,
      READY_DRIVER: `${URL}/admin/api/v1/drivers/`,
      ACTIVATE_DRIVER: `${URL}/admin/api/v1/drivers/activate/`,
      DEACTIVATE_DRIVER: `${URL}/admin/api/v1/drivers/deactivate/`,
      GET_ALL_DRIVERS_v1: `${URL}/admin/api/v1/drivers/`,
      GET_ALL_DRIVERS_v2: `${URL}/admin/api/v2/drivers/`,

      CREATE_DRIVER: `${URL}/admin/api/v1/drivers/register/`,
      UPDATE_DRIVER: `${URL}/admin/api/v1/drivers/update/`,
      EDIT_DRIVER: `${URL}/admin/api/v1/drivers/edit/`,
      CITC_DRIVER: `${URL}/admin/api/v1/drivers/citc/`,
      SEND_MESSAGE: `${URL}/admin/api/v1/messages/`,

      ATTACHMENT: `${URL}/v2/drivers/attachment/`,
      ATTACHMENT_v2: `${URL}/v2/drivers/attachment/`,
      EXPORT_EXCEL: `${URL}/admin/api/v1/drivers/excel/`,
      SYNC_ODOO: `${URL}/admin/api/v1/drivers/odoo/update/`,
      TOTAL_PAGE: `${URL}/admin/api/v1/drivers/total-page`,
      TOTAL_PAGE_SHUKAH: `${URL}/admin/api/v1/drivers/total-page-shukah`,

      SYNC_SHUKAH: `${URL}/admin/api/v1/drivers/maeda-shukah/async`,
      IMPORT_EXCEl: `${URL}/admin/api/v1/drivers/import-shukah/excel`,
      RE_CREATE: `${URL}/admin/api/v2/drivers/shukah/re-create`,
      OTP: `${URL}/admin/api/v2/drivers/otps`,
      DRIVER_ANALYSIS: (id) => `${URL}/admin/api/v1/drivers/${id}/worked-time`,
      TRANSACTIONS: `${URL}/admin/api/v2/drivers/filter-transactions`,

      ODM_ENABLE_BY_LIST: `${URL}/admin/api/v1/drivers/odm/enable-by-list`,
      ODM_DISABLE_ALL: `${URL}/admin/api/v1/drivers/odm/disable-all`,
      GET_VERSION_HISTORY: (id) => `${URL}/admin/api/v2/drivers/${id}/history`,
      CREATE_NOTE: (id) => `${URL}/admin/api/v3/drivers/${id}/note`,
    },
    TRANSACTIONS: {
      GET_ALL_TRANSACTIONS: `${URL}/admin/api/v1/transactions/`,
      CREATE_TRANSACTION: `${URL}/admin/api/v2/transactions/create/`,
      GET_DRIVER_TRANSACTIONS: (driverId) => `${URL}/admin/api/v1/drivers/${driverId}/transactions`,
      EXPORT_EXCEL: `${URL}/admin/api/v1/transactions/export/`,
      REFUND: (id) => `${URL}/admin/api/v1/payment/payfort/top-up/${id}/refund`,
    },
    LOCATION: {
      DRIVER_2: `${URL}/admin/api/v2/location/driver-near`,
      DRIVER: `${URL}/admin/api/v1/location/driver-near`,
      DRIVER_ASSIGN: `${URL}/admin/api/v1/location/driver-assign`,
      ORDER: `${URL}/admin/api/v1/location/orders`,
      EXPORT_EXCEL_DRIVERS: `${URL}/admin/api/v3/location/driver-near/excel`,
      CREATE_ORDER_ODM: `${URL}/admin/api/v1/orders/create-odm`,
    },
    CITC_LOGS: {
      // LIST: `${URL}/admin/api/v1/citc-logs/`,
      LIST: `${URL}/admin/api/v2/citc-logs/`,
      // DETAIL: `${URL}/admin/api/v1/citc-logs/`,
      DETAIL: `${URL}/admin/api/v2/citc-logs/`,
      REACTION: `${URL}/admin/api/v1/citc-logs/reaction/`,
      // TIMEOUT_LIST: `${URL}/admin/api/v1/citc-timeout-logs/`,
      TIMEOUT_LIST: `${URL}/admin/api/v2/citc-timeout-logs/`,
      // EXPORT_TIMEOUT_LIST: `${URL}/admin/api/v1/citc-timeout-logs/excel`,
      EXPORT_TIMEOUT_LIST: `${URL}/admin/api/v2/citc-timeout-logs/excel`,
      RECOVERY: `${URL}/admin/api/v1/citc-timeout-logs/recovery`,
      // EXPORT_ALl_LIST: `${URL}/admin/api/v1/citc-logs/excel`,
      EXPORT_ALl_LIST: `${URL}/admin/api/v2/citc-logs/excel`,
    },

    HTTP_LOGS: {
      // LIST: `${URL}/admin/api/v1/http-logs/`,
      LIST: `${URL}/admin/api/v2/http-logs/`,
      // DETAIL: (logId) => `${URL}/admin/api/v1/http-logs/${logId}`,
      DETAIL: (logId) => `${URL}/admin/api/v2/http-logs/${logId}`,
    },
    CITC_LOOKUP: {
      CAR_TYPES: `${URL}/admin/api/v1/lookups/car-types`,
      CITIES: `${URL}/admin/api/v1/lookups/cities`,
      REGIONS: `${URL}/admin/api/v1/lookups/regions`,
      IDENTITY_TYPES: `${URL}/admin/api/v1/lookups/identity-types`,
      REASONS: `${URL}/admin/api/v1/lookups/reasons-list`,
    },
    SETTING: {
      CREATE: `${URL}/admin/api/v1/settings/create`,
      GET: `${URL}/admin/api/v1/settings`,
      CLEAR_NOTIFICATIONS: `${URL}/admin/api/v1/messages/removeAll`,
      ODM: `${URL}/admin/api/v3/settings/odm-weightage`,
    },
    ANALYSIS: {
      ORDER: `${URL}/admin/api/v1/analysis/order`,
      DRIVER: `${URL}/admin/api/v1/analysis/driver`,
    },
    PRICING: {
      LIST: `${URL}/admin/api/v1/plans/list`,
      CREATE: `${URL}/admin/api/v1/plans/create`,
      PLAN_DETAIL: `${URL}/admin/api/v1/plans/`,
    },
    HYPERPAY_LOGS: {
      // LIST: `${URL}/admin/api/v1/hyper-logs/`,
      LIST: `${URL}/admin/api/v2/hyper-logs/`,
      // EXPORT_EXCEL: `${URL}/admin/api/v1/hyper-logs/excel`,
      EXPORT_EXCEL: `${URL}/admin/api/v2/hyper-logs/excel`,
    },
    PAYFORT: {
      GET_LIST: `${URL}/admin/api/v1/payfort-logs`,
      GET_DETAIL: (id) => `${URL}/admin/api/v1/payfort-logs/${id}`,
    },
    TRANSACTIONS_BY_PAYFORT: {
      GET_LIST: `${URL_PAYMENT_SERVICE}/admin/api/v1/logs-status-payment`,
      GET_LOG_DETAILS: (logId) =>
        `${URL_PAYMENT_SERVICE}/admin/api/v1/logs-status-payment/${logId}`,
    },
    ODOO_LOGS: {
      LIST: `${URL}/admin/api/v1/odoo-logs/`,
    },
    SHUKAH_LOGS: {
      // LIST: `${URL}/admin/api/v1/shukah-logs/`,
      LIST: `${URL}/admin/api/v2/shukah-logs/`,
    },
    DELIVERY_COMPANY: {
      LIST: `${URL}/admin/api/v1/companies/`,
      CREATE: `${URL}/admin/api/v1/companies/`,
      UPDATE: `${URL}/admin/api/v1/companies/`,
      DELETE: `${URL}/admin/api/v1/companies/`,
      PARTNER: {
        LIST: `${URL}/admin/api/v1/partners/`,
        CREATE: `${URL}/admin/api/v1/partners/create`,
        UPDATE: `${URL}/admin/api/v1/partners/`,
        DELETE: `${URL}/admin/api/v1/partners/`,
      },
    },
    NOTIFICATION: {
      MESSAGE: `${URL}/admin/api/v1/messages`,
      MESSAGE_DRIVER: `${URL}/admin/api/v1/messages/for-drivers`,
      MESSAGE_DRIVERS_MAP: `${URL}/admin/api/v3/drivers/send-notification`,
    },
    DRIVER_REGISTRATION: {
      GET_LIST: `${URL_SUPPLIER_SERVICES}/api/admin/v1/partners/registration-supplier`,
      // GET_DETAIL: (id) => `${URL_SUPPLIER_SERVICES}/admin/api/v1/payfort-logs/${id}`,
      GET_DETAIL: (id) =>
        `${URL_SUPPLIER_SERVICES}/api/admin/v1/partners/registration-detail/${id}`,
      PATCH_CHANGE_STATUS_SUPPLIER: (id) =>
        `${URL_SUPPLIER_SERVICES}/api/admin/v1/partners/change-status-supplier/${id}`,
      GET_LIST_BY_COMPANY: (companyId) =>
        `${URL_SUPPLIER_SERVICES}/api/admin/v1/companies/${companyId}/registration-drivers`,
    },
    STORES_REGISTRATION: {
      GET_LIST: `${URL}/admin/api/v1/stores/registrations`,
      PATCH_CHANGE_STATUS_REGISTRATION: (id) => `${URL}/admin/api/v1/stores/registrations/${id}`,
    },
    MARKETING_CAMPAIGN: {
      GET_LIST: `${URL_MARKETING_CAMPAIGN}/api/v1/operator/campaigns`,
      UPLOAD: `${URL_MARKETING_CAMPAIGN}/api/v1/operator/storage/upload`,
      CREATE: `${URL_MARKETING_CAMPAIGN}/api/v1/operator/campaigns`,
    },
    LOG_EXPORT: {
      GET_LIST: `${URL}/admin/api/v1/export-excel-logs`,
      UPLOAD: `${URL}/admin/api/v1/export-excel-logs`,
    },
    SUBSCRIPTION: {
      GET_LIST: `${URL}/admin/api/v1/subscriptions`,
      GET_LIST_DRIVER_SUBSCRIPTION: `${URL}/admin/api/v1/subscriptions/drivers/list`,
      GET_LIST_DRIVER_SUBSCRIPTION_ASSIGN: `${URL}/admin/api/v1/subscriptions/drivers/assign-manual`,
    },
  },
};

export const SETTING_KEY = {
  DRIVER_TIME_EXECUTE: 'DRIVER_TIME_EXECUTE',
  FEE_TAX: 'FEE_TAX',
  TIME_TRACKING: 'TIME_TRACKING',
  TOGGLE_CITC: 'TOGGLE_CITC',
  RECEIVED: 'RECEIVED',
  WHATSAPP: 'WHATSAPP',
  LIMIT_ORDER: 'LIMIT_ORDER',
  HYPERPAY: 'HYPERPAY',
  TIMEOUT_CITC: 'TIMEOUT_CITC',
  API_VER_PORTAL: 'API_VER_PORTAL',
  API_VER_MOBILE: 'API_VER_MOBILE',
  TOGGLE_ODOO: 'TOGGLE_ODOO',
  TOGGLE_ZOOM_MAP: 'TOGGLE_ZOOM_MAP',
  TIME_ZONE: 'GMT',
  TOGGLE_SHUKAH: 'TOGGLE_SHUKAH',
  ENABLE_ORDER_SOCKET: 'ENABLE_ORDER_SOCKET',
  PAYMENT_PLATFORM: 'PAYMENT_PLATFORM',
  PAYMENT_MODE: 'PAYMENT_MODE',
  MEMBERSHIP: 'MEMBERSHIP',
  SEND_ORDER_FILTERING: 'SEND_ORDER_FILTERING',
};

export const PERMISSIONS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};
