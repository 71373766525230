import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/Config';

import 'react-input-range/lib/css/index.css';
import SearchInput from '../SearchInput';
import { customStyles, newUrl, useColumnRatingOrder } from './useColumn';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { isArray, isEmpty } from 'lodash';
import LoadingButton from '../../commonComponents/LoadingButton';
import useEventEnter from '../../hooks/useEventEnter';
import { ratingStar } from '../../constants/constants';
import { IconRating } from '../../constants/Icon';
import DetailFeedback from '../RatingStoreDetail/DetailFeedback';
import { axiosGet } from '../../helper/axios/axiosGet';
import axiosInstance from '../../helper/axios/axiosInstance';
import moment from 'moment';
const DescriptionRating = ({
  type,
  filters,
  setFilters,
  meta,
  setMeta,
  setCurrentPage,
  currentPage,
  limit,
  setLimit,
  star,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const tableHeight = window.innerHeight - 390;
  const { columns } = useColumnRatingOrder();

  const [selectedId, setSelectedId] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [scoreRatingDriver, setScoreRatingDriver] = useState(0);
  const [scoreRatingStore, setScoreRatingStore] = useState(0);
  const [dataFocus, setDataFocus] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isExportRatings, setIsExportRatings] = useState(false);

  const newFilterParams = {
    ...(isArray(filters.date) && !isEmpty(filters.date)
      ? {
          'filter.toDate': filters?.date[1],
          'filter.fromDate': filters?.date[0],
        }
      : {}),

    'filter.driverStar': filters.driverStar,
    'filter.storeStar': filters.storeStar,
    'filter.merchant_id': filters.merchant_id,
    'filter.storePhone': filters.storePhone,
    'filter.storeName': filters.storeName,
    'filter.driverPhone': filters.driverPhone,
    'filter.driverName': filters.driverName,
    'filter.customerPhone': filters.customerPhone,
    'filter.orderNumber': filters.orderNumber,
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataRating = async () => {
    setLoading(true);

    const newFilters = {
      filters: {
        ...filters,
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };

    window.history.replaceState(
      { path: newUrl(newFilters, star, type) },
      '',
      newUrl(newFilters, star, type)
    );

    const response = await axiosGet(`${config.API_URL.RATING.RATING_OPERATION_RATING_START}`, {
      params: {
        ...newFilterParams,
        sort: -1,
        limit: limit,
        page: currentPage,
      },
      headers: config.headers,
    });
    const { data = [], meta = {} } = response?.data;
    setRatings(data);
    setMeta(meta);
    setLoading(false);
  };

  useEffect(() => {
    getDataRating();
  }, [limit, currentPage, type]);

  const toggleModal = (visible) => {
    setModalVisible(visible);
    if (!isEmpty(dataFocus)) {
      setDataFocus(null);
    }
  };
  const handleToggleModal = (data) => {
    toggleModal(true);
    setDataFocus(data);
  };

  const handleFilter = (value, type) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };
    setFilters(newFilters);
  };
  const onApplyFilter = () => {
    setCurrentPage(1);
    getDataRating();
  };

  useEventEnter(onApplyFilter);

  const handleFilterDriverStar = (value, type) => {
    if (value === scoreRatingDriver) {
      setScoreRatingDriver();
      const newFilters = {
        ...filters,
        driverStar: '',
      };
      setFilters(newFilters);
      return;
    }
    setScoreRatingDriver(Number(value));
    const newFilters = {
      ...filters,
      [type]: value,
    };
    setFilters(newFilters);
  };

  const handleFilterStoreStar = (value, type) => {
    if (value === scoreRatingStore) {
      setScoreRatingStore();
      const newFilters = {
        ...filters,
        storeStar: '',
      };
      setFilters(newFilters);
      return;
    }
    setScoreRatingStore(Number(value));
    const newFilters = {
      ...filters,
      [type]: value,
    };
    setFilters(newFilters);
  };

  const exportSTCExcel = () => {
    setIsExportRatings(true);

    try {
      axiosInstance
        .post(config.API_URL.RATING.RATING_OPERATION_RATING_START_EXCEL, null, {
          params: {
            ...newFilterParams,
          },
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `rating-all${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setIsExportRatings(false);
        });
    } catch (errors) {
      setIsExportRatings(false);
    } finally {
    }
  };

  return (
    <>
      <div className="flex justify-end">
        <button
          disabled={isExportRatings}
          className={`refresh-button direction-r ${
            isExportRatings ? 'opacity-40 border-red-200' : 'opacity-100'
          }`}
          onClick={exportSTCExcel}
        >
          {t('export_excel_all_by_filter')}
        </button>
      </div>

      <div className="px-8 py-5 flex items-end gap-4 flex-wrap justify-end">
        <div>
          <p className="font-semibold">{t('Driver_Star')}</p>
          <div className="flex gap-1 p-1 border rounded-lg">
            {ratingStar(scoreRatingDriver).map((values) => (
              <button
                key={values.value}
                type="button"
                onClick={() => handleFilterDriverStar(values.value, 'driverStar')}
              >
                <IconRating color={values.className} />
              </button>
            ))}
          </div>
        </div>

        <div>
          <p className="font-semibold">{t('Store_Star')}</p>
          <div className="flex gap-1 p-1 border rounded-lg">
            {ratingStar(scoreRatingStore).map((values) => (
              <button
                key={values.value}
                type="button"
                onClick={() => handleFilterStoreStar(values.value, 'storeStar')}
              >
                <IconRating color={values.className} />
              </button>
            ))}
          </div>
        </div>

        <div className="input-select-wrapper ltr">
          <DateTimeRangePicker
            className="input-select-input-wrapper !border-0 w-[200px]"
            disabled={loading}
            value={filters.date}
            onChange={(value) => handleFilter(value, 'date')}
            disableClock
            format={'y-MM-dd'}
          />
        </div>

        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('merchant_id')}
            handleSearch={(e) => handleFilter(e, 'merchant_id')}
            makeRerender={filters}
            value={filters?.merchant_id}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('restaurant_number')}
            handleSearch={(e) => handleFilter(e, 'storePhone')}
            makeRerender={filters}
            value={filters?.storePhone}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('restaurant_name')}
            handleSearch={(e) => handleFilter(e, 'storeName')}
            makeRerender={filters}
            value={filters?.storeName}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('shukah.driver.phone')}
            handleSearch={(e) => handleFilter(e, 'driverPhone')}
            makeRerender={filters}
            value={filters?.driverPhone}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('driver_name')}
            handleSearch={(e) => handleFilter(e, 'driverName')}
            makeRerender={filters}
            value={filters?.driverName}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('customer_number')}
            handleSearch={(e) => handleFilter(e, 'customerPhone')}
            makeRerender={filters}
            value={filters?.customerPhone}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('order_number')}
            handleSearch={(e) => handleFilter(e, 'orderNumber')}
            makeRerender={filters}
            value={filters?.orderNumber}
          />
        </div>
      </div>
      <div className="w-full flex items-start justify-end px-8 pb-8">
        <div className="">
          <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={ratings}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(+currentRowsPerPage)}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={meta?.totalItems}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        onRowClicked={(row) => handleToggleModal(row)}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={customStyles}
      />
      <DetailFeedback visible={modalVisible} toggleModal={toggleModal} dataFocus={dataFocus} />
    </>
  );
};

export default DescriptionRating;
